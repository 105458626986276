import {
  AddEventFileResponse,
  AddEventMessageRequest,
  AddEventRequest,
  DismissEventResponse,
  GetEventFileResponse_Success,
  Source,
  SubmitEventFormRequest,
  SubmitEventFormResponse,
} from '@api/app/v1/app_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import useAppService from './useAppService';
import { ErrorResponse, errorConsts } from './useMemberData';

import { getFirebaseAuthToken } from '@/firebase/auth';
import { Attachment, Event, Message } from '@/types/sheercases';

const useEventUpdates = (): {
  isLoading: boolean;
  events: Event[];
  attachments: Attachment[];
  listEvents: () => Promise<ErrorResponse | Event[]>;
  dismissEvent: (
    eventId: string,
  ) => Promise<ErrorResponse | DismissEventResponse>;
  uploadMultipleFiles: (files: File[], eventId: string) => Promise<any>;
  addEventMessage: (
    messageData: PartialMessage<AddEventMessageRequest>,
  ) => Promise<any>;
  createEvent: (
    eventData: PartialMessage<AddEventRequest>,
    messageData: { status: string; message: string },
    fileData?: File[],
  ) => Promise<any>;
  runInference: (eventId: string) => Promise<any>;
  submitEventForm: (
    data: PartialMessage<SubmitEventFormRequest>,
  ) => Promise<SubmitEventFormResponse | ErrorResponse>;
} => {
  const getClient = useAppService();
  const pathname = usePathname();
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  useEffect(() => {
    listEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (events.length) setIsLoading(false);
  }, [events]);

  const handleServerError = async (error: unknown) => {
    const isServerError = error
      ?.toString()
      .includes(errorConsts.noMemberFound.server);
    setIsLoading(false);

    if (isServerError)
      return { error: errorConsts.noMemberFound.client } as ErrorResponse;
    return { error: errorConsts.general.client } as ErrorResponse;
  };

  const listEvents = async () => {
    const token = await getFirebaseAuthToken();
    if (token) {
      return await getClient()
        .then(client =>
          client.listEvents({}, { headers: { Authorization: token } }),
        )
        .then(async response => {
          if ('error' in response) {
            setIsLoading(false);
            return { error: errorConsts.general.client };
          }
          const events = response?.events as Event[];
          const eventMessages = events.map(event => event.messages).flat();
          await fetchAllAttachments(eventMessages);

          setEvents(events);
          return events;
        })
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const addEvent = async (eventPayload: PartialMessage<AddEventRequest>) => {
    const token = await getFirebaseAuthToken();
    if (token) {
      return await getClient()
        .then(client =>
          client.addEvent(eventPayload, { headers: { Authorization: token } }),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const addEventMessage = async (
    eventMessagePayload: PartialMessage<AddEventMessageRequest>,
  ) => {
    if (!eventMessagePayload.message?.message) return Promise.resolve();
    const token = await getFirebaseAuthToken();

    if (token) {
      return await getClient()
        .then(client =>
          client.addEventMessage(
            { ...eventMessagePayload, source: Source.WEB },
            {
              headers: { Authorization: token },
            },
          ),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const addEventFile = async (eventId: string, file: File) => {
    const token = await getFirebaseAuthToken();
    const filePayload = {
      eventId,
      contentType: file.type || 'application/octet-stream',
      filename: file.name,
    };

    if (token) {
      return await getClient()
        .then(client =>
          client
            .addEventFile(
              { ...filePayload, source: Source.WEB },
              {
                headers: { Authorization: token },
              },
            )
            .then((res: AddEventFileResponse) => {
              const uploadUrl = res.uploadUrl;

              return fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                  'Content-Type': filePayload.contentType as string,
                  Authorization: token,
                },
                body: file,
              });
            }),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const uploadMultipleFiles = async (files: File[], eventId: string) => {
    return await Promise.all(
      files.map(file => addEventFile(eventId, file)),
    ).then(res => {
      const fileUploadFailed = res.some(r => r && 'error' in r);
      if (fileUploadFailed) throw Error('Error occurred while uploading files');

      return res;
    });
  };

  const createEvent = async (
    eventData: PartialMessage<AddEventRequest>,
    messageData: { status: string; message?: string },
    fileData: File[] = [],
  ) => {
    return await addEvent(eventData).then(async res => {
      if ('error' in res) return res;
      const eventId = res.eventId;
      const messagePayload = {
        eventId,
        message: messageData,
      };
      return await Promise.all([
        addEventMessage(messagePayload),
        uploadMultipleFiles(fileData, eventId),
      ]).then(() => {
        runInference(eventId);
        return eventId;
      });
    });
  };

  const dismissEvent = async (eventId: string) => {
    const eventPayload = { eventId };
    const token = await getFirebaseAuthToken();
    if (token) {
      return await getClient()
        .then(client =>
          client.dismissEvent(
            { ...eventPayload, source: Source.WEB },
            {
              headers: { Authorization: token },
            },
          ),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const getEventFile = async (attachmentId: string) => {
    const token = await getFirebaseAuthToken();

    if (token) {
      return await getClient()
        .then(client =>
          client.getEventFile(
            { attachmentId },
            {
              headers: { Authorization: token },
            },
          ),
        )
        .then(res => {
          const { response } = res;
          if (response.case === 'success' && response.value) {
            const { contentType, downloadUrl, filename } =
              response.value as GetEventFileResponse_Success;

            return {
              downloadUrl,
              contentType,
              filename,
              attachmentId,
            } as Attachment;
          } else {
            setIsLoading(false);
            return { error: errorConsts.general.client };
          }
        })
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const fetchAllAttachments = async (messages: Message[]) => {
    const attachmentIds = messages.map(m => m.attachmentId || null);
    const newAttachments = [] as Attachment[];
    await Promise.all(
      attachmentIds.map(async attachmentId => {
        if (attachmentId) {
          await getEventFile(attachmentId).then(attachment => {
            if (!('error' in attachment)) {
              newAttachments.push(attachment);
            }
          });
        }
      }),
    );
    setAttachments(newAttachments);
    return;
  };

  const runInference = async (eventId: string) => {
    const token = await getFirebaseAuthToken();

    if (token) {
      return await getClient()
        .then(client =>
          client.runEventInference(
            { eventId },
            {
              headers: { Authorization: token },
            },
          ),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const submitEventForm = async (
    data: PartialMessage<SubmitEventFormRequest>,
  ) => {
    const token = await getFirebaseAuthToken();
    const payload = {
      ...data,
      source: Source.WEB,
    } as PartialMessage<SubmitEventFormRequest>;

    if (token) {
      return await getClient()
        .then(client =>
          client.submitEventForm(payload, {
            headers: { Authorization: token },
          }),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  return {
    isLoading,
    events,
    attachments,
    listEvents,
    createEvent,
    uploadMultipleFiles,
    addEventMessage,
    dismissEvent,
    runInference,
    submitEventForm,
  };
};

export default useEventUpdates;
